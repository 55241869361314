import React, { FC } from 'react'
import FormattedHtmlContent from 'src/components/ui/FormattedHtmlContent'

export interface ITextItemProps {
  content: string
}

export interface ITextItemData extends ITextItemProps {
  id: string
  type: 'TextItem'
}

const TextItem: FC<ITextItemProps> = ({ content }) => (
  <FormattedHtmlContent
    className="mb-6 text-megaDarkGray font-semibold leading-none"
    dangerouslySetInnerHTML={{ __html: content }}
  />
)

export default TextItem
