// i18next-extract-mark-ns-start gui

import React, { FC } from 'react'
import FormattedHtmlContent from 'src/components/ui/FormattedHtmlContent'
import { directusDownloadUrl } from 'src/helpers/directus'
import Download from 'src/images/svg/download.svg'
import { useTranslation } from 'gatsby-plugin-react-i18next'

interface IDownloadListItemElementProps {
  file: {
    id: string
  }
  description: string
  className?: string
}

export interface IDownloadListItemElementData
  extends IDownloadListItemElementProps {
  id: string
}

const DownloadListItemElement: FC<IDownloadListItemElementProps> = ({
  file,
  description,
  className,
}) => {
  const { t } = useTranslation('gui')

  return (
    <div className={`flex justify-between ${className}`}>
      <a href={directusDownloadUrl(file.id)} rel="noreferrer">
        <FormattedHtmlContent
          className="text-megaDarkGray font-semibold"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </a>
      <div className="w-16 md:w-32" />
      <a href={directusDownloadUrl(file.id)} rel="noreferrer">
        <div className="flex justify-end items-center h-full w-9">
          <img src={Download} alt={t('Download')} />
        </div>
      </a>
    </div>
  )
}

DownloadListItemElement.defaultProps = {
  className: '',
}

export default DownloadListItemElement
