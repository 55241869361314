import styled from 'styled-components'
import tw from 'twin.macro'

const RedBulletList = styled.ul`
  li::marker {
    ${tw`text-red`}
  }
`

export default RedBulletList
