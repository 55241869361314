import React, { FC } from 'react'
import emphasizeAmpersand from 'src/helpers/emphasizeAmpersand'

interface IH4Props {
  firstPart: string
  secondPart: string
  className?: string
}

const SmallPageTitle: FC<IH4Props> = ({ firstPart, secondPart, className }) => (
  <h4 className={`font-palanquinDark text-2xl ${className}`}>
    <span className="block text-black">
      {emphasizeAmpersand(firstPart, 1.1)}
    </span>
    <span className="block text-red mt-0.5">
      {emphasizeAmpersand(secondPart, 1.1)}
    </span>
  </h4>
)

SmallPageTitle.defaultProps = {
  className: '',
}

export default SmallPageTitle
