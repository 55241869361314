import React, { FC } from 'react'
import FormattedHtmlContent from 'src/components/ui/FormattedHtmlContent'

interface IFoldableListItemElementProps {
  title: string
  content: string
  className?: string
}

export interface IFoldableListItemElementData
  extends IFoldableListItemElementProps {
  id: string
}

const FoldableListItemElement: FC<IFoldableListItemElementProps> = ({
  title,
  content,
  className,
}) => (
  <div className={className}>
    {!!title && (
      <h4 className="font-palanquinDark text-black md:text-lg mb-2">{title}</h4>
    )}
    <FormattedHtmlContent
      className="text-megaDarkGray font-semibold"
      dangerouslySetInnerHTML={{ __html: content }}
    />
  </div>
)

FoldableListItemElement.defaultProps = {
  className: '',
}

export default FoldableListItemElement
