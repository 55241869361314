import React, { FC } from 'react'
import DownloadListItemElement, {
  IDownloadListItemElementData,
} from 'src/components/ui/download/DownloadListItemElement'

export interface IDownloadListItemProps {
  elements: IDownloadListItemElementData[]
}

export interface IDownloadListItemData extends IDownloadListItemProps {
  id: string
  type: 'DownloadListItem'
}

const DownloadListItem: FC<IDownloadListItemProps> = ({ elements }) => (
  <div className="mb-6">
    {elements.map(({ id, file, description }) => (
      <DownloadListItemElement
        key={id}
        file={file}
        description={description}
        className="mb-7"
      />
    ))}
  </div>
)

export default DownloadListItem
