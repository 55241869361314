import React, { FC, useEffect, useState } from 'react'
import { isBrowser } from 'src/helpers/layout'
import styled from 'styled-components'
import tw from 'twin.macro'

interface ISideNavigationProps {
  className?: string
}

const StyledButton = styled.button`
  &.active::before {
    ${tw`block absolute w-2 h-2 bg-red -left-3 rounded-sm`}
    content: ' ';
  }
`

const SideNavigation: FC<ISideNavigationProps> = ({ className }) => {
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0)
  const [sections, setSections] = useState([])

  const getSections = (): HTMLElement[] =>
    Array.from(document.querySelectorAll('section[data-name], div[data-name]'))

  const getCurrentSectionIndex = () =>
    sections.findIndex((section) => {
      const SECTION_DETECTION_POINT = 300
      const rect = section.getBoundingClientRect()

      return (
        rect.top <= SECTION_DETECTION_POINT &&
        rect.bottom > SECTION_DETECTION_POINT
      )
    })

  const updateCurrentSection = () => {
    setCurrentSectionIndex(getCurrentSectionIndex())
  }

  const handleItemClick = (section: HTMLElement) => {
    const { top } = section.getBoundingClientRect()
    const { scrollY } = window
    const scrollToSectionOffset = 300

    window.scrollTo({
      top: top + scrollY - scrollToSectionOffset,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    setSections(getSections())
    updateCurrentSection()

    window.addEventListener('scroll', updateCurrentSection)
    return () => {
      window.removeEventListener('scroll', updateCurrentSection)
    }
  }, [currentSectionIndex])

  const getSingleMenuItem = (section: HTMLElement, isActive: boolean) => (
    <li
      className={`list-none ${
        isActive ? 'text-black' : 'text-secondaryGray600'
      }`}
    >
      <StyledButton
        onClick={() => handleItemClick(section)}
        type="button"
        className={`font-semibold leading-24px lowercase text-left mb-4 ${
          isActive ? 'active' : ''
        }`}
      >
        {section.dataset.name}
      </StyledButton>
    </li>
  )

  const getMappedSectionsToComponents = () => {
    if (!isBrowser) {
      return <></>
    }

    return getSections().map((section, index) => {
      const isActive = index === currentSectionIndex

      return getSingleMenuItem(section, isActive)
    })
  }

  return <div className={`${className}`}>{getMappedSectionsToComponents()}</div>
}
SideNavigation.defaultProps = {
  className: '',
}

export default SideNavigation
