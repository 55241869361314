// i18next-extract-mark-ns-start gui

import React, { FC } from 'react'
import FormattedHtmlContent from 'src/components/ui/FormattedHtmlContent'
import Follow from 'src/images/svg/follow.svg'
import { useTranslation } from 'gatsby-plugin-react-i18next'

interface IFollowBulletListItemElementProps {
  url: string
  description: string
  className?: string
}

export interface IFollowBulletListItemElementData
  extends IFollowBulletListItemElementProps {
  id: string
}

const FollowBulletListItemElement: FC<IFollowBulletListItemElementProps> = ({
  url,
  description,
  className,
}) => {
  const { t } = useTranslation('gui')

  return (
    <li className={className}>
      <div className="inline-flex justify-between w-full">
        <a href={url} rel="noreferrer" target="_blank">
          <FormattedHtmlContent
            className="text-megaDarkGray font-semibold"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </a>
        <div className="w-16 md:w-32" />
        <a href={url} rel="noreferrer" target="_blank">
          <div className="flex justify-end items-center h-full w-9">
            <img src={Follow} alt={t('Follow link')} />
          </div>
        </a>
      </div>
    </li>
  )
}

FollowBulletListItemElement.defaultProps = {
  className: '',
}

export default FollowBulletListItemElement
