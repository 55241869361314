import React, { FC } from 'react'
import DownloadListItem, {
  IDownloadListItemData,
} from 'src/components/ui/download/DownloadListItem'
import FoldableListItem, {
  IFoldableListItemData,
} from 'src/components/ui/fold/FoldableListItem'
import FollowBulletListItem, {
  IFollowBulletListItemData,
} from 'src/components/ui/follow/FollowBulletListItem'
import TextItem, { ITextItemData } from 'src/components/ui/TextItem'

type NavigableSectionItemData =
  | ITextItemData
  | IFoldableListItemData
  | IDownloadListItemData
  | IFollowBulletListItemData

export interface INavigableSectionProps {
  slug: string
  title: string
  items: { item: NavigableSectionItemData }[]
  className?: string
}

const NavigableSection: FC<INavigableSectionProps> = ({
  title,
  items,
  className,
}) => (
  <section data-name={title} id={title} className={className}>
    <h3 className="text-red font-palanquinDark font-bold text-sm md:text-base tracking-extreme mb-6">
      {title}
    </h3>
    {items.map(({ item }) => {
      const key = `${item.type}${item.id}`
      switch (item.type) {
        case 'TextItem':
          return <TextItem key={key} content={item.content} />
        case 'FoldableListItem':
          return (
            <FoldableListItem
              key={key}
              elements={item.elements}
              showMoreLabel={item.showMoreLabel}
            />
          )
        case 'DownloadListItem':
          return <DownloadListItem key={key} elements={item.elements} />
        case 'FollowBulletListItem':
          return <FollowBulletListItem key={key} elements={item.elements} />
        default:
          throw new Error('Unknown type of navigable section item')
      }
    })}
  </section>
)

export default NavigableSection
