import React, { FC } from 'react'
import FollowBulletListItemElement, {
  IFollowBulletListItemElementData,
} from 'src/components/ui/follow/FollowBulletListItemElement'
import RedBulletList from 'src/components/ui/RedBulletList'

export interface IFollowBulletListItemProps {
  elements: IFollowBulletListItemElementData[]
}

export interface IFollowBulletListItemData extends IFollowBulletListItemProps {
  id: string
  type: 'FollowBulletListItem'
}

const FollowBulletListItem: FC<IFollowBulletListItemProps> = ({ elements }) => (
  <RedBulletList className="list-square list-outside space-y-5 mb-6 ml-14">
    {elements.map(({ id, url, description }) => (
      <FollowBulletListItemElement
        key={id}
        url={url}
        description={description}
      />
    ))}
  </RedBulletList>
)

export default FollowBulletListItem
