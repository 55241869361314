// i18next-extract-mark-ns-start gui

import React, { FC, useState } from 'react'
import Button from 'src/components/ui/Button'
import FoldableListItemElement, {
  IFoldableListItemElementData,
} from 'src/components/ui/fold/FoldableListItemElement'

export interface IFoldableListItemProps {
  elements: IFoldableListItemElementData[]
  showMoreLabel: string
}

export interface IFoldableListItemData extends IFoldableListItemProps {
  id: string
  type: 'FoldableListItem'
}

const VISIBLE_COUNT_STEP = 2
const DEFAULT_NUMBER_OF_VISIBLE_SECTIONS = 2

const FoldableListItem: FC<IFoldableListItemProps> = ({
  elements,
  showMoreLabel,
}) => {
  const [visibleSectionsNumber, setVisibleSectionsNumber] = useState(
    Math.min(DEFAULT_NUMBER_OF_VISIBLE_SECTIONS, elements.length)
  )

  const showMore = () =>
    setVisibleSectionsNumber(
      Math.min(visibleSectionsNumber + VISIBLE_COUNT_STEP, elements.length)
    )

  return (
    <div className="mb-6">
      {elements
        .slice(0, visibleSectionsNumber)
        .map(({ id, title, content }) => (
          <FoldableListItemElement
            key={id}
            title={title}
            content={content}
            className="mb-4"
          />
        ))}

      {visibleSectionsNumber < elements.length && (
        <Button className="mt-2 font-semibold" onClick={showMore} isOutline>
          {showMoreLabel}
        </Button>
      )}
    </div>
  )
}

export default FoldableListItem
