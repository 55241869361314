import React, { FC } from 'react'
import Layout from 'src/components/layout'
import NavigableSection, {
  INavigableSectionProps,
} from 'src/components/sections/NavigableSection'
import SmallPageTitle from 'src/components/ui/title/H4'
import SideNavigation from 'src/components/layout/side-navigation'

interface IMetaProp {
  name?: string
  content?: string
}

interface INavigablePageProps {
  title: string
  metas: IMetaProp[]
  titlePart1: string
  titlePart2: string
  sections: INavigableSectionProps[]
}

const NavigablePage: FC<INavigablePageProps> = ({
  title,
  metas,
  titlePart1,
  titlePart2,
  sections,
}) => (
  <Layout title={title} metas={metas} isSideNavHidden>
    <div className="md:flex container mt-16 md:mt-28 py-10 md:py-20">
      <div className="md:w-1/3 mb-6 md:sticky self-start top-20">
        <SmallPageTitle firstPart={titlePart1} secondPart={titlePart2} />
        <SideNavigation className="mt-6 mb-8" />
      </div>
      <div className="md:w-2/3">
        {sections.map(({ slug, title: sectionTitle, items }) => (
          <NavigableSection
            slug={slug}
            title={sectionTitle}
            items={items}
            className="pb-11"
          />
        ))}
      </div>
    </div>
  </Layout>
)

export default NavigablePage
